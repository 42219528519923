import {
  applyTextType,
  buttonBaseStyle,
  buttonPrimaryStyle,
  buttonSecondaryStyle,
  Color,
  mediaQuery,
  RddkTheme,
} from 'db-npm-rdui';
import { css } from 'styled-components';
import * as tooltipStyles from './tooltipStyles';

/**
 * Editor styles for rich text area
 */

const headingStyle = css`
  margin-top: 31px;
  margin-bottom: 12px;
`;

const paragraphStyle = css`
  margin-top: 0;
`;
/* lists */
const richTextList = css`
  color: ${Color.black};
  list-style-position: inside;
  padding-left: 0;
  li {
    margin: 2px 0;
  }
`;
const olStyle = css`
  li {
    text-indent: -26px;
    padding-left: 26px;

    ${mediaQuery.md} {
      text-indent: -32px;
      padding-left: 32px;
    }
  }
  li::before {
    content: '';
    width: 10px;
    display: inline-block;
  }
`;
const ulStyle = css`
  list-style-type: none;
  li {
    text-indent: -6px;
    margin-left: 12px;
  }
  li::before {
    content: '-';
    display: inline-block;
    margin-right: 5px;
  }
`;

/* hr */
export const hrStyle = css`
  background: #f4f0f0;
  height: 3px;
  width: 100%;
  position: relative;
  border: none;
  overflow: visible;
  margin-top: 30px;
  margin-bottom: 17px;

  ::after {
    content: '';
    height: 3px;
    width: 100%;
    background: #f4f0f0;
    position: absolute;
    bottom: -7px;
    left: 0;
  }

  ${mediaQuery.md} {
    margin-top: 40px;
  }
  ${mediaQuery.xl} {
    margin-top: 50px;
  }
`;

/* Links */
const linkStyle = css`
  color: ${RddkTheme.colors.rdRed};
  text-decoration: underline;
`;

export const richTextStyles = css`
  /* Inline tooltip */
  .tooltip,
  .toolTip {
    ${tooltipStyles.tooltipLinkStyle}
  }
  .tooltip-bubble {
    ${tooltipStyles.tooltipBubble}
  }
  .tooltip-bubble__heading {
    ${tooltipStyles.tooltipHeading}
  }
  .tooltip-bubble__content {
    ${tooltipStyles.tooltipContent}
  }
  /* Text, list & link - default text style is paragraph */
  ${applyTextType('paragraph')}
  p {
    ${paragraphStyle}
  }
  a:not(.toolTip):not(.button) {
    ${linkStyle}
  }
  ol {
    ${richTextList}
    ${olStyle}
  }
  ul {
    ${richTextList}
    ${ulStyle}
  }
  hr {
    ${hrStyle}
  }
  .manchetBig {
    ${applyTextType('manchetBig')}
  }

  /* Headings */
  h1 {
    ${applyTextType('h1')}
    ${headingStyle}
  }
  h2 {
    ${applyTextType('h2')}
    ${headingStyle}
  }
  h3 {
    ${applyTextType('h3')}
    ${headingStyle}
  }
  h4 {
    ${applyTextType('h4')}
    ${headingStyle}
  }
  h5 {
    ${applyTextType('h5')}
    ${headingStyle}
  }
  h6 {
    ${applyTextType('h6')}
    ${headingStyle}
  }
  .button {
    ${buttonBaseStyle}
  }
  .button.primary {
    ${buttonPrimaryStyle}
  }
  .button.secondary {
    ${buttonSecondaryStyle}
  }
`;
